import {
    XAppButtonForRow,
    XFilterElementParams,
    XFilterElementProp,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React, {useEffect, useState} from "react";
import {VydavokForm} from "./VydavokForm";
import {Utils} from "../Utils";
import {XAggregateType, XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {StavVydavkuEnum, TypPristupuEnum, XEnum} from "../common/enums";
import {VydavokBrowseBudgetLineFilter} from "./VydavokBrowseBudgetLineFilter";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {SchvalitZrusitDialog, SchvalitZrusitDialogState} from "./SchvalitZrusitDialog";
import {DataTableFilterMeta} from "primereact/datatable";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {Pobocka} from "../model/pobocka.entity";
import {PobockaMena} from "../model/pobocka-mena.entity";
import {Vydavok} from "../model/vydavok.entity";
import {ColumnBodyOptions} from "primereact/column";
import {Tooltip} from "primereact/tooltip";
import {VydavokPriloha} from "../model/vydavok-priloha.entity";

export const VydavokBrowse = (props: {filters?: DataTableFilterMeta; customFilter?: XCustomFilter}) => {

    // koli skryvaniu niektorych stlpcov potrebujeme nacitat pobocky aj s detailami z DB, preto ich zapisujeme do state
    // (mohli by sme to nahradit nejakou cache ciselnikov ale po pripadnej zmene by si user musel restartnut aplikaciu)
    const [pobockaList, setPobockaList] = useState<Pobocka[]>([]);

    const [dataLoaded, setDataLoaded] = useState<boolean>(true); // boolean stav dataLoaded vytiahnuty z XLazyDataTable, aby sme mohli zavolat reload dat
                                                                        // umyselne sme nainicializovali na true - sposobi ze sa nevola load dat z DB
                                                                        // - load zavolame v metode loadData (potrebujeme volat load kusok neskor ako je to bezne)
    const [schvalitZrusitDialog, setSchvalitZrusitDialog] = useState<SchvalitZrusitDialogState>({opened: false});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        // TODO - zjednotit s kodom vo VydavokForm.preInitForm (idealne mat servis)
        let filter: XCustomFilter | undefined = Utils.filterCurrentOrganizacia();
        filter = XUtils.filterAnd(filter, Utils.filterCurrentPobocky("id", TypPristupuEnum.ReadOnly));
        const pobockaList: Pobocka[] = await XUtils.fetchRows("Pobocka", filter, "nazov", ["pobockaMenaList.mena.kod", "pobockaStrediskoList.nazov"]);
        setPobockaList(pobockaList);
        // sposobi zavolanie load-u dat z DB - je to potrebne urobit az po nacitani "pobockaList",
        // inac nam nezobrazuje hodnotu v stlpceku mena (koli columnViewStatus={sumyVMene} ktory zavisi od "pobockaList")
        setDataLoaded(false);
    }

    const onAddRow = () => {

        if (Utils.getCurrentProjekt() === null) {
            alert("Vyberte najprv projekt.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VydavokForm initValues={{
            projekt: Utils.getCurrentProjekt(),
            pobocka: null, // predplnuje sa vo VydavokForm.preInitForm, ked uz mame zisteny zoznam povolenych pobociek
            stavVydavku: StavVydavkuEnum.Vytvoreny,
            datumVystavenia: null,
            mena: null,
            kurz: null,
            hrubaSumaVMene: null,
            hrubaSuma: null,
            percento: 100,
            sumaVMene: null,
            suma: null,
            hrubaSumaVMeneFix: false,
            percentoFix: false,
            sumaVMeneFixZPercenta: false,
            sumaVMeneFixZBL: false,
            vydavokBudgetLineList: [{sumaVMene: null, suma: null}],
            vydavokPrilohaList: [],
            version: 0}}/>);
    }

    const onKopirovat = async (selectedRow: Vydavok) => {

        // skopirujeme vsetko okrem priloh (cize nejoinujeme atribut vydavokPrilohaList)
        // najoinujeme vsetky asociacie, aj tie ktore sa joinuju vo VydavokForm v konstruktore
        const vydavok: Vydavok = await XUtils.fetchById(
            "Vydavok",
            ["projekt.nazov", "pobocka.nazov", "typVydavku.name", "stredisko.nazov", "mena.kod", "vydavokBudgetLineList.budgetLine.budgetLinePobockaList.pobocka.id"],
            selectedRow.id);

        // zmazeme nezelane atributy
        vydavok.id = undefined!;
        vydavok.stavVydavku = StavVydavkuEnum.Vytvoreny;
        vydavok.modifDate = null!;
        vydavok.modifXUser = null!;
        vydavok.version = 0;
        vydavok.vydavokPrilohaList = [];

        // zmazeme id-cka, lebo inac robi update, my chceme insert
        for (const vydavokBudgetLine of vydavok.vydavokBudgetLineList) {
            vydavokBudgetLine.id = undefined!;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VydavokForm initValues={vydavok}/>);
    }

    const onEdit = (selectedRow: Vydavok) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VydavokForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: Vydavok): Promise<boolean> => {
        if (selectedRow.stavVydavku.code !== StavVydavkuEnum.Vytvoreny.code && selectedRow.stavVydavku.code !== StavVydavkuEnum.Kompletny.code) {
            alert("Vymazať je možné len záznam v stave vytvorený alebo kompletný.");
            return false;
        }

        if (!Utils.userMaPristup(selectedRow.pobocka, TypPristupuEnum.ReadWrite)) {
            alert(`Nemáte prístup k editácii/vymazaniu záznamov pobočky ${selectedRow.pobocka.nazov}.`);
            return false;
        }

        if (window.confirm(xLocaleOption('removeRowConfirm'))) {
            await XUtils.removeRow("Vydavok", selectedRow);
            return true;
        }
        return false;
    }

    const zmenitStav = (zoStavu: XEnum, doStavu: XEnum, operacia: string) => {
        if (!Utils.getCurrentProjekt()) {
            alert("Vyberte projekt.");
            return;
        }
        setSchvalitZrusitDialog({opened: true, zoStavu: zoStavu, doStavu: doStavu, operacia: operacia});
    }

    const schvalitZrusitDialogOnHide = (ok: boolean) => {
        setSchvalitZrusitDialog({opened: false});
        if (ok) {
            // refresh dat z DB
            setDataLoaded(false);
        }
    }

    const budgetLineFilterElement: XFilterElementProp = (params: XFilterElementParams): React.ReactNode => {
        return <VydavokBrowseBudgetLineFilter filterParams={params}/>;
    }

    const prilohyBody = (vydavok: Vydavok, options: ColumnBodyOptions): React.ReactNode => {
        let body: React.ReactNode = null;
        const pocetPriloh: number = vydavok.vydavokPrilohaList.length;
        if (pocetPriloh > 0) {
            const pocetPrilohId: string = "id" + vydavok.id.toString();
            body =
                <span>
                    <Tooltip target={`#${pocetPrilohId}`} /*position="left"*/>
                        {vydavok.vydavokPrilohaList.map((value: VydavokPriloha) => <div>{value.xFile.name}</div>)}
                    </Tooltip>
                    <span id={pocetPrilohId} className="pi pi-paperclip">{pocetPriloh > 1 ? pocetPriloh : null}</span>
                </span>;
        }
        return body;
    }

    let customFilter: XCustomFilter | undefined = {where: "[projekt] = :projektId", params: {"projektId": Utils.getCurrentProjektId()}};
    customFilter = XUtils.filterAnd(customFilter, Utils.filterCurrentPobocky("pobocka.id", TypPristupuEnum.ReadOnly));

    // na vytvorenie/vymazanie treba aspon read-write pristup
    // editaciu neobmedzujeme, editacia je zaroven aj prezeranie, az vo formulari v metode formReadOnly zistujeme ci moze editovat
    const canAddRemoveRow = Utils.userMaPristup(null, TypPristupuEnum.ReadWrite);

    let appButtonsForRow: XAppButtonForRow[] = [];
    if (canAddRemoveRow) {
        appButtonsForRow.push({key: "pridat-kopiu", icon: "pi pi-plus", label: "Pridať kópiu", onClick: onKopirovat});
    }
    // na buttony treba pristup admin pobocky alebo vyssi
    // ak sa jedna o pracovnika pobocky tak este pri vybere zaznamov filtrujeme len zaznamy tych pobociek, pre ktore ma pristup AdminPobocky
    let appButtons: React.ReactNode[] = [];
    if (Utils.userMaPristup(null, TypPristupuEnum.AdminPobocky)) {
        appButtons.push(<XButton key="uzatvorit" label="Uzatvoriť záznamy" onClick={() => zmenitStav(StavVydavkuEnum.Kompletny, StavVydavkuEnum.Uzatvoreny, "Uzatvoriť záznamy")}/>);
        appButtons.push(<XButton key="zrusitUzatvorenie" label="Zrušiť uzatvorenie" onClick={() => zmenitStav(StavVydavkuEnum.Uzatvoreny, StavVydavkuEnum.Kompletny, "Zrušiť uzatvorenie")}/>);
        appButtons.push(<XButton key="schvalit" label="Schváliť záznamy" onClick={() => zmenitStav(StavVydavkuEnum.Uzatvoreny, StavVydavkuEnum.Schvaleny, "Schváliť záznamy")}/>);
        appButtons.push(<XButton key="zrusitSchvalenie" label="Zrušiť schválenie" onClick={() => zmenitStav(StavVydavkuEnum.Schvaleny, StavVydavkuEnum.Uzatvoreny, "Zrušiť schválenie")}/>);
        appButtons.push(<SchvalitZrusitDialog key="schvalitZrusitDialog" dialogState={schvalitZrusitDialog} onHideDialog={schvalitZrusitDialogOnHide}/>);
    }
    // ak sme prisli z reportu, pridame tlacitko Naspet
    if (props.filters !== undefined) {
        appButtons.push(<XButton key="naspet" label="Naspäť" onClick={() => (props as any).openForm(null)}/>);
    }

    // ak mame nejaku pobocku so strediskami, tak zobrazime aj stlpec stredisko
    const viewStatusStredisko: boolean = pobockaList.some((pobocka: Pobocka) => pobocka.pobockaStrediskoList.length > 0);

    // ak mame nejaku pobocku s menami, tak zobrazime aj sumy v cudzej mene
    const sumyVMene: boolean = pobockaList.some((pobocka: Pobocka) => pobocka.pobockaMenaList.length > 0);
    const hlavnaMenaKod: string = sumyVMene ? ` ${Utils.getCurrentProjekt()?.mena.kod ?? "EUR"}` : "";
    const menaIdList: number[] = [];
    for (const pobocka of pobockaList) {
        menaIdList.push(...(pobocka.pobockaMenaList.map((pobockaMena: PobockaMena) => pobockaMena.mena.id)));
    }
    const filterMena: XCustomFilter = XUtils.filterIdIn("id", menaIdList);

    return (
        <XLazyDataTable entity="Vydavok" sortField="id" rows={30} filters={props.filters} customFilter={XUtils.filterAnd(customFilter, props.customFilter)} betweenFilter="column"
                        onAddRow={canAddRemoveRow ? onAddRow : undefined} onEdit={onEdit} removeRow={canAddRemoveRow ? onRemoveRow : undefined}
                        dataLoadedState={[dataLoaded, setDataLoaded]}
                        appButtonsForRow={appButtonsForRow} appButtons={appButtons} multilineSwitch={true}
                        exportFieldsToDuplicateValues={["id", "pobocka.nazov", "stavVydavku.name", "typVydavku.name", "datumVystavenia", "datumSplatnosti", "stredisko.nazov", "cisloFaktury"]}
                        displayed={(props as any).displayed}>
            <XLazyColumn field="id" header="ID" width="4rem"/>
            <XLazyColumn field="pobocka.nazov" header="Pobočka" width="7rem" dropdownInFilter={true} dropdownFilter={XUtils.filterIdIn("id", pobockaList.map((value: Pobocka) => value.id))} columnViewStatus={pobockaList.length > 1}/>
            <XLazyColumn field="stavVydavku.name" header="Stav" width="7rem" dropdownInFilter={true} dropdownSortField="id"/>
            <XLazyColumn field="typVydavku.name" header="Typ výdavku" width="9rem" dropdownInFilter={true} dropdownSortField="id"/>
            <XLazyColumn field="datumVystavenia" header="Dátum dokladu" align="center"/>
            <XLazyColumn field="datumSplatnosti" header="Dátum platby" align="center"/>
            <XLazyColumn field="stredisko.nazov" header="Stredisko" width="10rem" columnViewStatus={viewStatusStredisko}/>
            <XLazyColumn field="cisloFaktury" header="Číslo dokladu" width="9rem"/>
            <XLazyColumn field="strucnyPopis" header="Popis výdavku" width="17rem"/>
            <XLazyColumn field="strucnyPopisAnglicky" header="Popis výdavku anglicky" width="17rem" columnViewStatus={Utils.getCurrentProjekt()?.pouzitStrucnyPopisAnglicky ?? false}/>
            <XLazyColumn field="dodavatel" header="Dodávateľ" width="17rem"/>
            <XLazyColumn field="vydavokPrilohaList.xFile.name" header="Príl." width="2.5rem" body={prilohyBody} filterElement={(params: XFilterElementParams) => null} showFilterMenu={false}/>{/* nezobrazime filter (trosku hack) */}
            <XLazyColumn field="sumaVMene" header="% v mene" width="7rem" aggregateType={XAggregateType.Sum} columnViewStatus={sumyVMene}/>
            <XLazyColumn field="mena.kod" header="Mena" width="6rem" dropdownInFilter={true} dropdownFilter={filterMena} columnViewStatus={sumyVMene}/>
            <XLazyColumn field="suma" header={sumyVMene ? `%${hlavnaMenaKod}` : `Suma %`} width="7rem" aggregateType={XAggregateType.Sum}/>
            <XLazyColumn field="vydavokBudgetLineList.sumaVMene" header="BL v mene" width="7rem" aggregateType={XAggregateType.Sum} columnViewStatus={sumyVMene}/>
            <XLazyColumn field="vydavokBudgetLineList.suma" header={sumyVMene ? `BL${hlavnaMenaKod}` : `Suma BL`} width="7rem" aggregateType={XAggregateType.Sum}/>
            <XLazyColumn field="vydavokBudgetLineList.budgetLine.kodNazov" header="Budget line" width="27rem" filterElement={budgetLineFilterElement} showFilterMenu={false}/>
        </XLazyDataTable>
    );
}
